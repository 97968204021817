import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import SubHeader from '../components/SubHeaderProfile';
// import * as ROUTES from '../src/constants/routes';

class IkedaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        device: 'pc',
    };
}
componentDidMount() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
        this.setState({ device: 'sp' });
    } else {
        this.setState({ device: 'pc' });
    }
}

jiko() {
  window.location.href="#jiko";
}
jiko2() {
  window.location.href="#jiko2";
}
haikei() {
window.location.href="#haikei";
}
daiji() {
window.location.href="#daiji";
}


  render() {
    return (
      <Fragment>
      <SubHeader />
      <title>コーチ 前田翔平 國學院大學久我山高等学校 バスケットボール部</title>
  <div className="ikeda-background">

  <div className="profile-container">
  <div className="profile-area" id="jiko">
  <h2 className="content-title-white-profile">自己紹介</h2>
  {this.state.device =="sp" && (
  <div className="profile-maintext">
  <div>名前：前田 翔平 </div><div>(まえだ　しょうへい)</div>
  <div>生まれ：東京都</div>
  <div>出身中学校：</div><div>國學院大学久我山中学校</div>
  <div>出身高校：</div><div>國學院大學久我山高校</div>
  <div>出身大学：東京経済大学</div>
  </div>
)}
{this.state.device =="pc" && (
<div className="profile-maintext">
<div>名前：前田 翔平 (まえだ　しょうへい)</div>
<div>生まれ：東京都</div>
<div>出身中学校：國學院大学久我山中学校</div>
<div>出身高校：國學院大學久我山高等学校</div>
<div>出身大学：東京経済大学</div>
</div>
)}
<div className="arrowWrap" onClick={this.jiko2}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>

<div className="profile-area-main" id="jiko2">
<h2 className="content-title-white-profile">これまでの経歴</h2>
{this.state.device =="sp" && (
  <div className="profile-maintext">
  小学校からバスケットを始める。久我山高校バスケ部に憧れ、中学から國學院久我山に入学。高校時代はチームとして全国大会に2度出場。高校卒業後は一度バスケットから離れたが、大学3年生から久我山高校バスケ部の学生コーチとして活動中。
  </div>
)}
{this.state.device =="pc" && (
  <div>
  <div className="profile-maintext">
  小学校からバスケを始め、中学校では東京都代表選手に選出される。高校では、全国大会の常連校でバスケを学びたいと決意し、國學院久我山高校に入学。在学中は全国大会へ３度出場、副キャプテンを務めた。高校卒業後は、大学に通いながら母校の中学生の指導を始める。大学生２年生から卒業までの約３年間は、國學院久我山高校の学生コーチとして全国ベスト8をはじめ、インターハイ、ウインターカップともに出場。
  </div>
  </div>
)}
<div className="arrowWrap" onClick={this.haikei}>
  <div className="arrow1">
    <span></span>
  </div>
  <div className="arrow2">
    <span></span>
  </div>
</div>
</div>



<div className="profile-area-main" id="haikei">
<h2 className="content-title-white-profile">コーチ就任背景</h2>
<div className="profile-maintext">
2023年4月から酒井監督に誘われ、國學院久我山バスケ部学生コーチに就任。
</div>
<div className="profile-maintext">
　生徒との年齢の近さを活かし、距離の近いコミュニケーションを心がけている。生徒の悩みや不安を解消し、一人一人がバスケットに全力で取り組めるような環境づくりを大切にしている。
</div>

</div>


</div>
  </div>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <IkedaPage />
  </Layout>
);
